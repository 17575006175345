import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import Meta from 'vue-meta'
import VueLazyload from 'vue-lazyload'
const loadimage = require('@/assets/news/loading.png')
import '@/utlis/vant'
import '@/utlis/remcommon.js'
import shell from '@/utlis/shell.js'
import global from '@/utlis/global'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
if (typeof window !== 'undefined') {
	// 此代码块只在客户端执行
	const firebaseConfig = window.__FIREBASE_CONFIG__ || shell.firebaseConfig // 从 window 对象获取 Firebase 配置
	let app = null
	let analytics = null

	isSupported().then((result) => {
		if (result) {
			app = initializeApp(firebaseConfig)
			analytics = getAnalytics(app)
			logEvent(analytics, 'in_page')
			console.log('in_page')
		}
	})

	Vue.prototype.$logEvent = (event, params = {}) => {
		console.log(event)
		isSupported().then((result) => {
			if (result && analytics) {
				// 检查 analytics 是否已初始化
				logEvent(analytics, event, params)
			}
		})
	}
	Vue.prototype.$eventrackFb = (msg, method, map = {}) => {
		let params = {
			time: new Date(),
			message: msg,
			method: method,
			...map,
		}
		console.log(params)
		isSupported().then((result) => {
			if (result) {
				logEvent(analytics, msg, params)
			}
		})
	}
} else {
	// 对于服务器端，你可以定义一个简单的函数或者不做任何事情
	Vue.prototype.$logEvent = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
	Vue.prototype.$eventrackFb = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
}

// simple event

Vue.prototype.$global = global
Vue.use(Meta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: loadimage,
	attempt: 1,
})

export default function createApp() {
	const router = createRouter()
	const app = new Vue({
		router,
		data() {
			return {
				baseInfo: null,
				shell: null,
				MobileOperatingSystem: false,
			}
		},
		beforeMount() {
			this.MobileOperatingSystem = this.$global.getMobileOperatingSystem()
		},
		methods: {
			clientFunc(name, data) {
				console.log(`与客户端交互方法：${name} 参数↓`, data)
				const executeNativeInteraction = () => {
					// 检查 JsCallAndroid 是否存在以及它的方法是否存在
					if (typeof JsCallAndroid === 'undefined' || typeof JsCallAndroid[name] !== 'function') {
						console.log('JsCallAndroid 未定义或相应方法不存在，回退到 Web 实现')
						return executeWebInteraction()
					}

					switch (name) {
						case 'msgFromH5':
							return JsCallAndroid.msgFromH5(data)
						// case 'dataFromH5':
						// 	return JsCallAndroid.dataFromH5(data)
						default:
							console.log('Unknown method name:', name)
							return null
					}
				}

				const executeWebInteraction = () => {
					switch (name) {
						case 'msgFromH5':
							console.log(`msgFromH5 msg-->${data}`)
							break
						case 'dataFromH5':
							console.log(`dataFromH5 msg-->${data}`)
							break
						default:
							console.log('Unknown method name:', name)
							return null
					}
				}

				// Decide based on the OS
				return this.MobileOperatingSystem ? executeNativeInteraction() : executeWebInteraction()
				// return executeWebInteraction()
			},
		},
		render: (h) => h(App),
	})
	return {
		app,
		router,
	}
}
