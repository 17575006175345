/*
 * @Author: xin.song
 * @Date: 2020-03-12 17:09:05
 * @Last Modified by: xin.song
 * @Last Modified time: 2024-05-22 16:35:52
 */

// import { api } from './service.js'
import { service } from './service.js'

const server = {
	getNews(req) {
		return service.get(`${process.env.VUE_APP_BASE_API}/external/news/detail`, req)
	},
	getList(req) {
		return service.get(`${process.env.VUE_APP_BASE_API}/external/news/list`, req)
	},
}
/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
	if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return 'Windows Phone'
		}

		if (/android/i.test(userAgent)) {
			return 'Android'
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return 'iOS'
		}
	}

	return null
}

function GetQueryString(str) {
	if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
		var reg = new RegExp('(^|&)' + str + '=([^&]*)(&|$)', 'i')
		var r = window.location.search.substr(1).match(reg)
		if (r != null) return unescape(r[2])
	}

	return null
}

function formatDate(oldDate, fmt) {
	// oldDate is 13 digits
	// fmt for formatting, e.g. yyyy-MM-dd hh:mm
	let date = new Date()
	if (typeof oldDate === 'string' || typeof oldDate === 'number') {
		date = new Date(+oldDate)
	} else {
		date = oldDate
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}

	const o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
	}

	const padLeftZero = (str) => ('00' + str).substr(str.length)

	Object.keys(o).forEach((k) => {
		if (new RegExp(`(${k})`).test(fmt)) {
			const str = o[k] + ''
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
		}
	})

	return fmt
}



const firebaseConfig = {
	apiKey: 'AIzaSyCsac4dnFfhSrTG4yLVjQwqEkR6moymIKM',
	authDomain: 'mumusiic-c62a1.firebaseapp.com',
	projectId: 'mumusiic-c62a1',
	storageBucket: 'mumusiic-c62a1.appspot.com',
	messagingSenderId: '830411448500',
	appId: '1:830411448500:web:9acc97b290f3c47ae737e1',
	measurementId: 'G-C3DEJN4RH9',
}

const shell = {
	slot1: '3204092613',
	slot2: '9318994694',
	slot3: '6899046613',
	client: 'ca-pub-8381337407740666',
	scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8381337407740666',
	cid: 97,
	ads: 'google.com,pub-8381337407740666,DIRECT,f08c47fec0942fa0',
}

// 暴露出这些属性和方法
export default {
	GetQueryString,
	formatDate,
	server,
	shell,
	firebaseConfig,
	getMobileOperatingSystem
}
