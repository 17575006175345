const config = {
	'apiebay.art': {
		slot1: '4929125872',
		slot2: '1891898995',
		slot3: '1700327309',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	firebaseConfig: {
		apiKey: 'AIzaSyCsac4dnFfhSrTG4yLVjQwqEkR6moymIKM',
		authDomain: 'mumusiic-c62a1.firebaseapp.com',
		projectId: 'mumusiic-c62a1',
		storageBucket: 'mumusiic-c62a1.appspot.com',
		messagingSenderId: '830411448500',
		appId: '1:830411448500:web:9acc97b290f3c47ae737e1',
		measurementId: 'G-C3DEJN4RH9',
	},
	default: {
		slot1: '123',
		slot2: '234',
		slot3: '345',
		client: 'ca-pub-1234',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1234',
		cid: 97,
		ads: '232',
	},
	'localhost:4988': {
		slot1: '2180675624',
		slot2: '5928348947',
		slot3: '8362940592',
		client: 'ca-pub-4836343775460964',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4836343775460964',
		cid: 97,
		ads: 'google.com, pub-4836343775460964, DIRECT, f08c47fec0942fa0',
	},
	'apiebay.sbs': {
		slot1: '1508190963',
		slot2: '6392851984',
		slot3: '5255864286',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apicostco.sbs': {
		slot1: '2992663025',
		slot2: '1679581355',
		slot3: '6126711371',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apitmall.sbs': {
		slot1: '6924169170',
		slot2: '1769745536',
		slot3: '7881279192',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apicdiscount.sbs': {
		slot1: '9741904204',
		slot2: '5802659197',
		slot3: '3176495855',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apiwish.sbs': {
		slot1: '8454573388',
		slot2: '8444300253',
		slot3: '4839348738',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apicoupang.sbs': {
		slot1: '2332852316',
		slot2: '9767655053',
		slot3: '1019770642',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apishein.sbs': {
		slot1: '8827443636',
		slot2: '7498884249',
		slot3: '8778593744',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apilazada.sbs': {
		slot1: '9386680980',
		slot2: '5447435970',
		slot3: '8264422576',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apishopee.sbs': {
		slot1: '2049533865',
		slot2: '7049992372',
		slot3: '7968909100',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apicoupang.art': {
		slot1: '1360017157',
		slot2: '5107690476',
		slot3: '3794608804',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apishopee.art': {
		slot1: '7514679199',
		slot2: '5064857247',
		slot3: '6186367225',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apishein.art': {
		slot1: '4986935816',
		slot2: '8283769720',
		slot3: '6108445798',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apiwish.art': {
		slot1: '3290710760',
		slot2: '8351465753',
		slot3: '1168445466',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apicdiscount.art': {
		slot1: '4152953026',
		slot2: '8855363795',
		slot3: '3099139073',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apitmall.art': {
		slot1: '4916118789',
		slot2: '9472975732',
		slot3: '2272308411',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apicostco.art': {
		slot1: '2908154118',
		slot2: '8472220411',
		slot3: '9959226749',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apilazada.art': {
		slot1: '2929648493',
		slot2: '5364240143',
		slot3: '6294178438',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
	'apiwalmart.art': {
		slot1: '6037628762',
		slot2: '4724547091',
		slot3: '2098383757',
		client: 'ca-pub-8908317842209223',
		scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8908317842209223',
		cid: 97,
		ads: 'google.com,pub-8908317842209223,DIRECT,f08c47fec0942fa0',
	},
}

module.exports = config
